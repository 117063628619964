<template>
  <div class="z-flex-center z-shadow home" style="overflow: hidden">
    <div
      class="z-primary-light-bg z-flex z-flex-column w-9/10 rounded-5px chat-content"
    >
      <div
        class="z-flex z-align-center z-p-2 z-border-bottom z-primary-disabled-bd"
      >
        <el-avatar
          src="https://gw.alicdn.com/imgextra/i2/O1CN01WuJjJo23ffJnMoVUy_!!6000000007283-2-tps-156-156.png_80x80.jpg"
        >
        </el-avatar>
        <div class="z-flex-1 z-ml-1 z-flex z-flex-column z-font-26">
          <span> 在线客服 </span>
          <span class="z-font-24 z-tips-color mt-5px"> 521官方旗舰店 </span>
        </div>
      </div>
      <div
        class="z-flex-1 z-w-100 z-py-2"
        ref="chat_content"
        style="overflow-y: auto; padding-right: 20px"
      >
        <div v-for="(item, index) in dataList" :key="index">
          <div v-if="item.type == 'TEXT'">
            <div :class="item.messageType === 1 ? 'word-my' : 'word'">
              <div class="info">
                <div class="info-content">{{ item.sendText }}</div>
              </div>
            </div>
          </div>
          <div v-if="item.type == 'IMAGE'">
            <div :class="item.messageType === 1 ? 'word-my' : 'word'">
              <div class="info">
                <el-image
                  :src="item.sendText"
                  :preview-src-list="[item.sendText]"
                ></el-image>
              </div>
            </div>
          </div>
          <div v-if="item.type == 'GOODS1'" @click="dingdanxiang(item)">
            <div :class="item.messageType === 1 ? 'word-my' : 'word'">
              <div class="info1">
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="5">
                    <el-image
                      :src="item.sendText.goodsInfoList[0].file"
                      class="image"
                    ></el-image>
                  </el-col>
                  <el-col :span="10">
                    <div class="text">
                      {{ item.sendText.goodsInfoList[0].goodsName }}
                    </div>
                    <div class="text">
                      ￥<span class="jiner">{{
                        item.sendText.subtotalPrice
                      }}</span>
                    </div>
                  </el-col>
                </el-row>
                <div class="dingdanhao">
                  订单号：{{ item.sendText.orderAttachNumber }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.type == 'GOODS'" @click="shangpinxiang(item)">
            <div :class="item.messageType === 1 ? 'word-my' : 'word'">
              <div class="info1">
                <el-row :gutter="20">
                  <el-col :span="5">
                    <el-image
                      :src="item.sendText.file"
                      class="image"
                    ></el-image>
                  </el-col>
                  <el-col :span="10">
                    <div class="text">{{ item.sendText.goodsName }}</div>
                    <div class="text">
                      ￥<span class="jiner">{{ item.sendText.shopPrice }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="z-shadow z-flex z-flex-column z-position-relative h-180px z-font-17"
      >
        <!-- 表情区域 -->
        <div class="browBox left-0" v-if="faceShow" style="position: absolute">
          <ul>
            <li
              v-for="(item, index) in faceList"
              class=""
              :key="index"
              @click="getBrow(item)"
            >
              <img :src="item.file" width="50" />
              <div>{{ item.goodsName }}</div>
              <div>￥{{ item.shopPrice }}</div>
            </li>
          </ul>
        </div>
        <div class="browBox left-0" v-if="faceShow1" style="position: absolute">
          <ul>
            <li
              v-for="(item, index) in dingdanList"
              class=""
              :key="index"
              @click="getBrow1(item)"
            >
              <img :src="item.goodsInfoList[0].file" width="50" />
              <div>订单号：{{ item.orderAttachNumber }}</div>
              <div>￥{{ item.subtotalPrice }}</div>
            </li>
          </ul>
        </div>
        <div class="z-flex-1 z-flex z-align-center">
          <el-upload
            :data="{ type: 'other_img' }"
            :http-request="uploadHttp"
            :before-upload="beforeUpload"
            :show-file-list="false"
            ref="upload"
            action=""
          >
            <MyIcon
              :icon_class="['bi-image', 'text-40px', 'text-55px', 'z-mx-2']"
            />
            <p>图片</p>
          </el-upload>
          <div class="ceary">
            <MyIcon
              @myClick="faceContent"
              :icon_class="
                faceShow
                  ? ['bi-bag-dash', 'z-primary-color', 'text-50px', 'mx-30px']
                  : ['bi-bag-dash', 'text-50px', 'mx-30px']
              "
            />
            <p>商品</p>
          </div>
          <div class="ceary">
            <MyIcon
              @myClick="faceContent1"
              :icon_class="
                faceShow1
                  ? [
                      'bi-receipt-cutoff',
                      'z-primary-color',
                      'text-50px',
                      'mx-30px',
                    ]
                  : ['bi-receipt-cutoff', 'text-50px', 'mx-30px']
              "
            />
            <p>订单</p>
          </div>
        </div>
        <div class="z-flex-1 z-flex">
          <el-input
            placeholder="请输入内容"
            class="h-100px"
            v-model="msg_content"
            @keyup.enter.native="inputEnter"
          />
          <el-button type="primary" @click="sendText"
            >发送<i class="el-icon-s-promotion ml-20px text-30px"></i
          ></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emojis from "@/assets/js/emojis";
import alioss from "@/assets/js/alioss";
import { randomString } from "@/assets/js/common.js";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HomeView",
  data() {
    return {
      msg_content: "",
      faceShow: false,
      faceShow1: false,
      faceList: [],
      dataList: [],
      customerId: "",
      memberId: "",
      dingdanList: [],
    };
  },
  computed: {
    /**
     * 数组形式
     * 当映射的计算属性的名称与 与模块中vuexTest中state 的子节点名称相同时，我们也可以给 mapState 传一个字符串数组，
     * */
    ...mapState([
      "vuex_from_id",
      "vuex_to_id",
      "vuex_chat_record",
      "vuex_page_visible",
    ]),
  },
  watch: {
    vuex_chat_record(newVal, oldVal) {
      console.log(newVal, "zyzyzyzyzyzyz", oldVal);
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.$nextTick(() => {
          this.$refs.chat_content.scrollTop =
            this.$refs.chat_content.scrollHeight;
        });
      }
    },
  },
  filters: {
    dateTimeFormat(v) {
      return v;
    },
  },
  methods: {
    ...mapActions(["init", "sendMsg"]),
    ...mapMutations([
      "SET_VUEX_CHAT_RECORD",
      "SET_VUEX_PAGE_VISIBLE",
      "SET_VUEX_TO_ID",
      "SET_VUEX_USERTYPE",
      "SET_VUEX_MEMBERID",
    ]),
    huoqu() {
      console.log("wwwwww", window.location.search);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramValue = urlParams.get("memberId");
      const paramValue1 = urlParams.get("storeId");
      console.log(paramValue);
      console.log(paramValue1);
      this.memberId = paramValue;
      this.SET_VUEX_MEMBERID(paramValue);
    },
    // 上传前过滤
    beforeUpload(file) {
      let ext = file.name.split(".")[file.name.split(".").length - 1];
      if (ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "gif") {
        return true;
      }
      this.$message.error("图片文件格式错误~");
      return false;
    },
    // 图片名随机
    getImgName() {
      // 时间戳毫秒
      let time_stamp = new Date().getTime();
      return randomString() + time_stamp;
    },
    /**
     * 阿里云OSS上传
     */
    uploadHttp({ file, data }) {
      let file_name = this.getImgName() + file.name;
      alioss.put(file_name, file).then((res) => {
        this.senImg(res.url);
      });
    },
    // 获取用户点击之后的标签 ，存放到输入框内
    getBrow(index) {
      console.log(index, "lc");
      let msg_one = {
        sendText: index,
        type: "GOODS", //TEXT 文本 IMAGE 图片
        // memberId: "0",
        recipientId: 0,
        customerType: 1,
        messageType: 1,
        senderId: this.memberId,
      };

      this.sendMsg(msg_one);
      this.faceShow = false;
    },
    // 获取用户点击之后的标签 ，存放到输入框内
    getBrow1(index) {
      console.log(index, "lc");
      let msg_one = {
        sendText: index,
        type: "GOODS1", //TEXT 文本 IMAGE 图片
        // memberId: "0",
        recipientId: 0,
        customerType: 1,
        messageType: 1,
        senderId: this.memberId,
      };

      this.sendMsg(msg_one);
      this.faceShow1 = false;
    },
    // 表情
    faceContent() {
      this.faceShow = !this.faceShow;
      if (this.faceShow == true) {
        this.$apiFun
          .selectBrowsingGistory({
            memberId: this.memberId,
            // storeId: this.data.service_info.TARGET_ID,
          })
          .then((res) => {
            console.log(res, "zyxaaaaaa");
            this.faceList = res.rows;
          })
          .catch((error) => {
            console.log(error, "zyx");
          });
        // for (let i in emojis) {
        //   this.faceList.push(emojis[i].char);
        // }
      } else {
        this.faceList = [];
      }
    },
    faceContent1() {
      this.faceShow1 = !this.faceShow1;
      if (this.faceShow1 == true) {
        this.$apiFun
          .selectOrderInfoList({
            memberId: this.memberId,
            // storeId: this.data.service_info.TARGET_ID,
          })
          .then((res) => {
            console.log(res, "zyxaaaaaa");
            this.dingdanList = res.rows;
          })
          .catch((error) => {
            console.log(error, "zyx");
          });
        // for (let i in emojis) {
        //   this.faceList.push(emojis[i].char);
        // }
      } else {
        // this.faceList = [];
      }
    },
    goDown() {
      // setTimeout(() => {
      //     this.$nextTick(() => {
      //         this.$refs.chat_content.scrollTop = this.$refs.chat_content.scrollHeight;
      //     })
      // }, 300)
    },
    senImg(img_url) {
      //   if (!this.vuex_to_id) {
      //     return;
      //   }
      let msg_one = {
        sendText: img_url,
        type: "IMAGE", //TEXT 文本 IMAGE 图片
        // memberId: "0",
        recipientId: 0,
        customerType: 1,
        messageType: 1,
        senderId: this.memberId,
      };
      this.sendMsg(msg_one);
      // 表情栏关闭
      this.faceShow = false;
      this.goDown();
    },
    sendText() {
      if (!this.msg_content) {
        return;
      }
      let msg_one = {
        sendText: this.msg_content,
        type: "TEXT", //TEXT 文本 IMAGE 图片
        // memberId: "0",
        recipientId: 0,
        customerType: 1,
        messageType: 1,
        senderId: this.memberId,
      };

      this.sendMsg(msg_one);

      // 重置输入框内容
      this.msg_content = "";
      // 表情栏关闭
      this.faceShow = false;
      this.goDown();
    },
    inputEnter() {
      this.sendText();
    },
    getChatRecord() {
      this.$apiFun
        .selectMemberChatMessage({
          memberId: this.memberId,
          customerType: 1,
          customerId: this.customerId,
          // storeId: this.data.service_info.TARGET_ID,
        })
        .then((res) => {
          console.log(res, "zyx");
          for (let i = 0; i < res.rows.length; i++) {
            if (res.rows[i].type === "GOODS") {
              res.rows[i].sendText = JSON.parse(res.rows[i].sendText);
            } else if (res.rows[i].type === "GOODS1") {
              res.rows[i].sendText = JSON.parse(res.rows[i].sendText);
            }
          }
          this.dataList = res.rows;
          console.log(this.dataList, "zyzyzyzyzyzyz");
        })
        .catch((error) => {
          console.log(error, "zyx");
        });
      // this.huoqu()
    },
    getselectActiveCustomer() {
      this.$apiFun
        .selectActiveCustomer({
          memberId: this.memberId,
          customerType: 1,
          // storeId: this.data.service_info.TARGET_ID,
        })
        .then((res) => {
          console.log(res, "111111");
          this.SET_VUEX_TO_ID(res.data.customerId);
          this.customerId = res.data.customerId;
          this.SET_VUEX_USERTYPE(res.data.userType);
        })
        .catch((error) => {});
    },
    // 1 将vuex_page_visible设为true,2将对方发的消息设为已读
    // pageVisibleAndMsg2True() {
    //   this.SET_VUEX_PAGE_VISIBLE(true);
    //   // 如果对方uid存在
    //   if (this.vuex_to_id) {
    //     this.$apiFun.updateNoReadToRead({
    //       from_id: this.vuex_to_id,
    //       to_id: this.vuex_from_id,
    //     });
    //   }
    // },
    handleVisiable(e) {
      switch (e.target.visibilityState) {
        // case 'prerender':
        //   console.log('网页预渲染，内容不可见')
        //   break;
        case "hidden":
          // 页面不显示
          this.SET_VUEX_PAGE_VISIBLE(false);
          // console.log('内容不可见，处理后台、最小化、锁屏状态')
          break;
        case "visible":
          // console.log('处于正常打开')
          // this.pageVisibleAndMsg2True();
          break;
      }
    },
  },
  timers: {
    getChatRecord: {
      time: 5000,
      autostart: true,
      immediate: true,
      repeat: true,
    },
    getselectActiveCustomer: {
      time: 6000,
      autostart: true,
      immediate: true,
      repeat: true,
    },
  },
  mounted() {
    document.title = "在线客服...";
    document.addEventListener("visibilitychange", this.handleVisiable);
    //第一次加载打开页面,1 将vuex_page_visible设为true,2将对方发的消息设为已读
    // this.pageVisibleAndMsg2True();

    this.init();

    this.goDown();
    this.huoqu();
  },
  created() {
    // console.log(md5Str.getRandomMd5Str())
  },
};
</script>

<style lang="scss" scoped>
.ceary{
  text-align: center;
}
.home {
  height: 100vh;

  background: url(https://img.alicdn.com/tfs/TB1C.3nefb2gK0jSZK9XXaEgFXa-2880-2000.jpg)
    0 0 no-repeat;
  background-size: cover;

  .home-title {
    width: 100%;
    width: 960px;
    height: 700px;
    border-radius: 5px;
  }
}

.browBox {
  z-index: 9999;
  cursor: default;
  width: 100%;
  height: 400px;
  overflow: auto;
  background: #fff;
  position: absolute;
  bottom: 180px;
  overflow-y: auto;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;

    li {
      width: 14%;
      font-size: 30px;
      list-style: none;
      text-align: center;
      margin-top: 7px;
      img {
        margin: 0 auto;
      }
      div {
        font-size: 20px;
        word-break: keep-all; /* 不换行 */
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
        text-overflow: ellipsis;
      }
    }
  }
}
.word {
  display: flex;
  margin-bottom: 20px;

  img {
    width: 30%;
    height: 30%;
  }

  .info {
    margin-left: 10px;

    .time {
      font-size: 12px;
      color: rgba(51, 51, 51, 0.8);
      margin: 0;
      height: 20px;
      line-height: 20px;
      margin-top: -5px;
    }

    .info-content {
      /* max-width: 70%; */
      padding: 10px;
      font-size: 14px;
      background: #f3f3f3;
      /* position: relative; */
      margin-top: 8px;
      border-radius: 5px;
    }

    /* 小三角形 */
    .info-content::before {
      position: absolute;
      left: -8px;
      top: 8px;
      content: "";
      border-right: 10px solid #f3f3f3;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }
}

.word-my {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  // width: 500px;
  /* 
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    } */

  .info {
    width: 90%;
    margin-left: 10px;
    text-align: right;

    .time {
      font-size: 12px;
      color: rgba(51, 51, 51, 0.8);
      margin: 0;
      height: 20px;
      line-height: 20px;
      margin-top: -5px;
      margin-right: 10px;
    }

    .info-content {
      max-width: 70%;
      padding: 10px;
      font-size: 14px;
      float: right;
      margin-right: 10px;
      position: relative;
      margin-top: 8px;
      background: #d0e9ff;
      text-align: left;
      border-radius: 5px;
    }

    /* 小三角形 */
    .info-content::after {
      position: absolute;
      right: -8px;
      top: 8px;
      content: "";
      border-left: 10px solid #d0e9ff;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }
  .info1 {
    width: 50%;
    background-color: #f3f3f3;
    border-radius: 7px;
    .image {
      width: 200px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .text {
      margin-top: 10px;
      margin-left: 50px;
      font-size: 20px;
      .jiner {
        color: red;
        font-weight: bold;
      }
    }
    .dingdanhao {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
