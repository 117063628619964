<template>
	<i :class="icon_class" @click="myClick"></i>
</template>

<script>
	export default {
		props: {
			icon_class: {
				type: Array,
				default: () => {
					return ['bi-telephone', 'text-secondary']
				}
			},
			size: {
				type: String,
				default: '1'
			}
		},
		methods: {
			myClick() {
				this.$emit('myClick')
			}
		}
	}
</script>
