import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import Router from '@/router'
const _axios = axios.create({
	baseURL: 'https://interface.book521.com/api/', //基础请求地址
	timeout: 30000, //最大请求时间
})

/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
	config => {
		//接受JSON数据格式
		config.headers['Content-Type'] = 'application/json;charset=utf-8';
		// config.headers["Access-Control-Allow-Origin"] = "http://127.0.0.1:9080";
		// //如果有需要在这里开启请求时的loading动画效果
		config.headers['access-token'] = ''; //添加token,需要结合自己的实际情况添加，
		return config;
	},
	err => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
	res => {
		//在这里关闭请求时的loading动画效果
		//这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
		const {
			status,
			message
		} = res

		if (status == 0) { //失败
			Vue.prototype.$message.error(message);
		} else if (status == 200) { //成功
			return res.data;
		} else if (status == 8888) { //token失效
			Router.push({
				path: '/login',
			})
		}
	},
	err => {
		if (err) {
			//在这里关闭请求时的loading动画效果
			console.log(err)
		}
		return Promise.reject(err);
	}
);

//封装post,get方法
const http = {
	get(url = '', params = {}) {
		return new Promise((resolve, reject) => {
			_axios({
				url,
				params,
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				},
				method: 'GET'
			}).then(res => {
				resolve(res)
			}).catch(error => {
				reject(error)
			})
		})
	},

	post(url = '', params = {}) {
		return new Promise((resolve, reject) => {
			_axios({
				url,
				data: params,
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				},
				method: 'POST'
			}).then(res => {
				console.log('Response received:', res);
				resolve(res);
			}).catch(error => {
				console.error('Request failed:', error);
				reject(error);
			});
		});
	}
}


export default http