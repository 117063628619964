import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'windi.css'

import VueTimers from 'vue-timers'
Vue.use(VueTimers)

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

import apiFun from "./assets/js/api";
Vue.prototype.$apiFun = apiFun; //请求接口api


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import 'bootstrap-icons/font/bootstrap-icons.css'
import MyIcon from './components/MyIcon/MyIcon'
import 'windi.css'
Vue.component('MyIcon', MyIcon)


Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')
