
    /**

       * @description: 获取任意长度的随机数字字母组合字符串

       * @param {*} len 随机字符串的长度

       * @return {*}

       */
    const randomString = (len=6) => {

        const charSet = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
        let str = ''
        for (let i = 0; i < len; i++) {
            let index = Math.ceil(Math.random()*35);
            str += charSet[index];
        }
        return str;

    }


    export {
        randomString
    };