//引入刚才的http.js文件
import https from './http.js';

//设置个对象，就不用一个个暴露了，直接暴露对象
let apiFun = {};

// 绑定UID
// apiFun.bindUid = (params) => {
// 	return https.post('/bindUid', params)
// }
// 发送消息
// apiFun.sendMsg = (params) => {
// 	return https.post('/selectActiveCustomer', params)
// }

// // 根据 fromid 和 toid获取聊天数据
// apiFun.getChatRecord = (params) => {
// 	return https.post('/getChatRecord', params)
// }

// // 将未读消息变成已读
// apiFun.updateNoReadToRead = (params) => {
// 	return https.post('/updateNoReadToRead', params)
// }

// 将未读消息变成已读
apiFun.selectActiveCustomer = (params) => {
	return https.post('/selectActiveCustomer', params)
}

// 将未读消息变成已读
apiFun.selectMemberChatMessage = (params) => {
	return https.post('/selectMemberChatMessage', params)
}

// 将未读消息变成已读
apiFun.selectBrowsingGistory = (params) => {
	return https.post('/selectBrowsingGistory', params)
}
// 将未读消息变成已读
apiFun.selectOrderInfoList = (params) => {
	return https.get('/selectOrderInfoList', params)
}


//暴露出这个对象
export default apiFun;
