const emojis = {
    "grinning": {
        "keywords": ["face", "smile", "happy", "joy", ":D", "grin"],
        "char": "😀",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "grin": {
        "keywords": ["face", "happy", "smile", "joy", "kawaii"],
        "char": "😁",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "smiley": {
        "keywords": ["face", "happy", "joy", "haha", ":D", ":)", "smile", "funny"],
        "char": "😃",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "smile": {
        "keywords": ["face", "happy", "joy", "funny", "haha", "laugh", "like", ":D", ":)"],
        "char": "😄",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "sweat_smile": {
        "keywords": ["face", "hot", "happy", "laugh", "sweat", "smile", "relief"],
        "char": "😅",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "laughing": {
        "keywords": ["happy", "joy", "lol", "satisfied", "haha", "face", "glad", "XD", "laugh"],
        "char": "😆",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "innocent": {
        "keywords": ["face", "angel", "heaven", "halo"],
        "char": "😇",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "wink": {
        "keywords": ["face", "happy", "mischievous", "secret", ";)", "smile", "eye"],
        "char": "😉",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "blush": {
        "keywords": ["face", "smile", "happy", "flushed", "crush", "embarrassed", "shy", "joy"],
        "char": "😊",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "slightly_smiling_face": {
        "keywords": ["face", "smile"],
        "char": "🙂",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "yum": {
        "keywords": ["happy", "joy", "tongue", "smile", "face", "silly", "yummy", "nom", "delicious", "savouring"],
        "char": "😋",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "heart_eyes": {
        "keywords": ["face", "love", "like", "affection", "valentines", "infatuation", "crush", "heart"],
        "char": "😍",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "kissing_heart": {
        "keywords": ["face", "love", "like", "affection", "valentines", "infatuation", "kiss"],
        "char": "😘",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "kissing": {
        "keywords": ["love", "like", "face", "3", "valentines", "infatuation", "kiss"],
        "char": "😗",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "kissing_smiling_eyes": {
        "keywords": ["face", "affection", "valentines", "infatuation", "kiss"],
        "char": "😙",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "kissing_closed_eyes": {
        "keywords": ["face", "love", "like", "affection", "valentines", "infatuation", "kiss"],
        "char": "😚",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "stuck_out_tongue_winking_eye": {
        "keywords": ["face", "prank", "childish", "playful", "mischievous", "smile", "wink", "tongue"],
        "char": "😜",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "stuck_out_tongue_closed_eyes": {
        "keywords": ["face", "prank", "playful", "mischievous", "smile", "tongue"],
        "char": "😝",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "stuck_out_tongue": {
        "keywords": ["face", "prank", "childish", "playful", "mischievous", "smile", "tongue"],
        "char": "😛",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "sunglasses": {
        "keywords": ["face", "cool", "smile", "summer", "beach", "sunglass"],
        "char": "😎",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "roll_eyes": {
        "keywords": ["face", "eyeroll", "frustrated"],
        "char": "🙄",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "flushed": {
        "keywords": ["face", "blush", "shy", "flattered"],
        "char": "😳",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "rage": {
        "keywords": ["angry", "mad", "hate", "despise"],
        "char": "😡",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "confused": {
        "keywords": ["face", "indifference", "huh", "weird", "hmmm", ":/"],
        "char": "😕",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "tired_face": {
        "keywords": ["sick", "whine", "upset", "frustrated"],
        "char": "😫",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "triumph": {
        "keywords": ["face", "gas", "phew", "proud", "pride"],
        "char": "😤",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "fearful": {
        "keywords": ["face", "scared", "terrified", "nervous", "oops", "huh"],
        "char": "😨",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "disappointed_relieved": {
        "keywords": ["face", "phew", "sweat", "nervous"],
        "char": "😥",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "sleepy": {
        "keywords": ["face", "tired", "rest", "nap"],
        "char": "😪",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "sweat": {
        "keywords": ["face", "hot", "sad", "tired", "exercise"],
        "char": "😓",
        "fitzpatrick_scale": false,
        "category": "people"
    },

    "dizzy_face": {
        "keywords": ["spent", "unconscious", "xox", "dizzy"],
        "char": "😵",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "astonished": {
        "keywords": ["face", "xox", "surprised", "poisoned"],
        "char": "😲",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "sneezing_face": {
        "keywords": ["face", "gesundheit", "sneeze", "sick", "allergy"],
        "char": "🤧",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "mask": {
        "keywords": ["face", "sick", "ill", "disease"],
        "char": "😷",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "face_with_thermometer": {
        "keywords": ["sick", "temperature", "thermometer", "cold", "fever"],
        "char": "🤒",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "face_with_head_bandage": {
        "keywords": ["injured", "clumsy", "bandage", "hurt"],
        "char": "🤕",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "sleeping": {
        "keywords": ["face", "tired", "sleepy", "night", "zzz"],
        "char": "😴",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "zzz": {
        "keywords": ["sleepy", "tired", "dream"],
        "char": "💤",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "clap": {
        "keywords": ["hands", "praise", "applause", "congrats", "yay"],
        "char": "👏",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "call_me_hand": {
        "keywords": ["hands", "gesture"],
        "char": "🤙",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "+1": {
        "keywords": ["thumbsup", "yes", "awesome", "good", "agree", "accept", "cool", "hand", "like"],
        "char": "👍",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "-1": {
        "keywords": ["thumbsdown", "no", "dislike", "hand"],
        "char": "👎",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "facepunch": {
        "keywords": ["angry", "violence", "fist", "hit", "attack", "hand"],
        "char": "👊",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "fist": {
        "keywords": ["fingers", "hand", "grasp"],
        "char": "✊",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "v": {
        "keywords": ["fingers", "ohyeah", "hand", "peace", "victory", "two"],
        "char": "✌",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "ok_hand": {
        "keywords": ["fingers", "limbs", "perfect", "ok", "okay"],
        "char": "👌",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "raised_hand": {
        "keywords": ["fingers", "stop", "highfive", "palm", "ban"],
        "char": "✋",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "raised_back_of_hand": {
        "keywords": ["fingers", "raised", "backhand"],
        "char": "🤚",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "muscle": {
        "keywords": ["arm", "flex", "hand", "summer", "strong", "biceps"],
        "char": "💪",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "handshake": {
        "keywords": ["agreement", "shake"],
        "char": "🤝",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "point_left": {
        "keywords": ["direction", "fingers", "hand", "left"],
        "char": "👈",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "point_right": {
        "keywords": ["fingers", "hand", "direction", "right"],
        "char": "👉",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "fu": {
        "keywords": ["hand", "fingers", "rude", "middle", "flipping"],
        "char": "🖕",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "raised_hand_with_fingers_splayed": {
        "keywords": ["hand", "fingers", "palm"],
        "char": "🖐",
        "fitzpatrick_scale": true,
        "category": "people"
    },
    "lips": {
        "keywords": ["mouth", "kiss"],
        "char": "👄",
        "fitzpatrick_scale": false,
        "category": "people"
    },
    "ear": {
        "keywords": ["face", "hear", "sound", "listen"],
        "char": "👂",
        "fitzpatrick_scale": true,
        "category": "people"
    },

    "eyes": {
        "keywords": ["look", "watch", "stalk", "peek", "see"],
        "char": "👀",
        "fitzpatrick_scale": false,
        "category": "people"
    },

    "santa": {
        "keywords": ["festival", "man", "male", "xmas", "father christmas"],
        "char": "🎅",
        "fitzpatrick_scale": true,
        "category": "people"
    },

    "sun_with_face": {
        "keywords": ["nature", "morning", "sky"],
        "char": "🌞",
        "fitzpatrick_scale": false,
        "category": "animals_and_nature"
    },
    "crescent_moon": {
        "keywords": ["night", "sleep", "sky", "evening", "magic"],
        "char": "🌙",
        "fitzpatrick_scale": false,
        "category": "animals_and_nature"
    },
    "star": {
        "keywords": ["night", "yellow"],
        "char": "⭐",
        "fitzpatrick_scale": false,
        "category": "animals_and_nature"
    },

    "zap": {
        "keywords": ["thunder", "weather", "lightning bolt", "fast"],
        "char": "⚡",
        "fitzpatrick_scale": false,
        "category": "animals_and_nature"
    },
    "fire": {
        "keywords": ["hot", "cook", "flame"],
        "char": "🔥",
        "fitzpatrick_scale": false,
        "category": "animals_and_nature"
    },

    "snowflake": {
        "keywords": ["winter", "season", "cold", "weather", "christmas", "xmas"],
        "char": "❄️",
        "fitzpatrick_scale": false,
        "category": "animals_and_nature"
    },

    "soccer": {
        "keywords": ["sports", "football"],
        "char": "⚽",
        "fitzpatrick_scale": false,
        "category": "activity"
    },
    "basketball": {
        "keywords": ["sports", "balls", "NBA"],
        "char": "🏀",
        "fitzpatrick_scale": false,
        "category": "activity"
    },
    "football": {
        "keywords": ["sports", "balls", "NFL"],
        "char": "🏈",
        "fitzpatrick_scale": false,
        "category": "activity"
    },
    "baseball": {
        "keywords": ["sports", "balls"],
        "char": "⚾",
        "fitzpatrick_scale": false,
        "category": "activity"
    },
    "gift": {
        "keywords": ["present", "birthday", "christmas", "xmas"],
        "char": "🎁",
        "fitzpatrick_scale": false,
        "category": "objects"
    },

    "tada": {
        "keywords": ["party", "congratulations", "birthday", "magic", "circus", "celebration"],
        "char": "🎉",
        "fitzpatrick_scale": false,
        "category": "objects"
    },

    "black_nib": {
        "keywords": ["pen", "stationery", "writing", "write"],
        "char": "✒️",
        "fitzpatrick_scale": false,
        "category": "objects"
    },
    "memo": {
        "keywords": ["write", "documents", "stationery", "pencil", "paper", "writing", "legal", "exam", "quiz", "test", "study", "compose"],
        "char": "📝",
        "fitzpatrick_scale": false,
        "category": "objects"
    },
    "heart": {
        "keywords": ["love", "like", "valentines"],
        "char": "❤️",
        "fitzpatrick_scale": false,
        "category": "symbols"
    },
    "yellow_heart": {
        "keywords": ["love", "like", "affection", "valentines"],
        "char": "💛",
        "fitzpatrick_scale": false,
        "category": "symbols"
    },
    "green_heart": {
        "keywords": ["love", "like", "affection", "valentines"],
        "char": "💚",
        "fitzpatrick_scale": false,
        "category": "symbols"
    },
    "vs": {
        "keywords": ["words", "orange-square"],
        "char": "🆚",
        "fitzpatrick_scale": false,
        "category": "symbols"
    },
    "speech_balloon": {
        "keywords": ["bubble", "words", "message", "talk", "chatting"],
        "char": "💬",
        "fitzpatrick_scale": false,
        "category": "symbols"
    },

    "clock1": {
        "keywords": ["time", "late", "early", "schedule"],
        "char": "🕐",
        "fitzpatrick_scale": false,
        "category": "symbols"
    }
}
export default emojis;