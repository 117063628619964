import Vue from 'vue'
import Vuex from 'vuex'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import $apiFun from "@/assets/js/api";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		vuex_ws: '', //ws对象
		vuex_from_id: '',
		vuex_to_id: "",
		vuex_to_name: "",
		vuex_chat_record: [],
		vuex_page_visible: false, //聊天页面是否显示
		// 实例化一个音频播放器对象
		vuex_audio: '',
		vuex_userType: '',
		vuex_memberId: '',
	},
	getters: {},
	mutations: {
		SET_VUEX_AUDIO: (state) => {
			let audio = new Audio()
			audio.src = 'https://dingdingkefu-bucket.oss-cn-chengdu.aliyuncs.com/5c890e14d729595308.mp3'
			state.vuex_audio = audio
		},
		SET_VUEX_PAGE_VISIBLE: (state, vuex_page_visible) => {
			state.vuex_page_visible = vuex_page_visible
		},
		SET_VUEX_TO_NAME: (state, vuex_to_name) => {
			state.vuex_to_name = vuex_to_name
		},
		SET_VUEX_FROM_ID: (state, vuex_from_id) => {
			state.vuex_from_id = vuex_from_id
		},
		SET_VUEX_TO_ID: (state, vuex_to_id) => {
			state.vuex_to_id = vuex_to_id
		},
		SET_VUEX_CHAT_RECORD: (state, vuex_chat_record) => {
			state.vuex_chat_record = vuex_chat_record
		},
		SET_VUEX_WS: (state, vuex_ws) => {
			state.vuex_ws = vuex_ws
		},
		SET_VUEX_USERTYPE: (state, vuex_userType) => {
			state.vuex_userType = vuex_userType
		},
		SET_VUEX_MEMBERID: (state, vuex_memberId) => {
			state.vuex_memberId = vuex_memberId
		},
	},
	actions: {
		async init({
			state,
			commit,
			dispatch
		}) {

			commit('SET_VUEX_AUDIO')

			if (state.vuex_ws && state.vuex_ws.readyState == 1) {
				return
			}

			// 实例化socket，这里的实例化直接赋值给ws是为了后面可以在其它的函数中也能调用websocket方法，例如：ws.close(); 完成通信后关闭WebSocket连接
			let ws = new WebSocket('wss://interface.book521.com/websocket/'+state.vuex_memberId+'?userType=' + state.vuex_userType)
			await commit('SET_VUEX_WS', ws)

			// 监听
			//监听是否连接成功
			state.vuex_ws.onopen = async () => {
				console.log('ws连接状态：' + state.vuex_ws.readyState);
			}
			// state.vuex_ws.send = async () => {
			// 	console.log('ws连接状态：' + state.vuex_ws.readyState);
			// }

			//接听服务器发回的信息并处理展示
			state.vuex_ws.onmessage = async (e) => {
				let visitorId = await dispatch('getUniqueCode')
				let data = JSON.parse(e.data)
				console.log('zzzzzzz', data);
				if (data.type == 'bind') {
					await dispatch('bindUid', {
						client_id: data.msg_content,
						visitorId: visitorId
					})
				} else if (data.type == 'msg') {

					// 1 如果当前页面显示状态，将发过来的消息设为已读
					if (state.vuex_page_visible) {
						await $apiFun.updateNoReadToRead({
							from_id: data.from_id,
							to_id: data.to_id
						})
					}

					try {
						await state.vuex_audio.play()
					} catch (e) {
						state.vuex_audio.play()
					}

					// 消息插入本地消息记录中
					// commit('SET_VUEX_TO_ID', data.from_id)
					// commit('SET_VUEX_TO_NAME', data.from_name)
					// let chat_record = state.vuex_chat_record
					// chat_record.push(data)
					// commit('SET_VUEX_CHAT_RECORD', chat_record)

				} else {

				}
			}

			//监听连接关闭事件
			state.vuex_ws.onclose = () => {
				//监听整个过程中websocket的状态
				// console.log('ws连接状态：' + state.vuex_ws.readyState);
				commit('SET_VUEX_WS', '')
				dispatch('init')
			}

			//监听并处理error事件
			state.vuex_ws.onerror = function (error) {
				console.log(error);
			}
		},
		bindUid({
				state,
				commit,
				dispatch
			},
			data) {
			// 发起绑定请求 将client_id 与 visitorId进行绑定
			$apiFun.bindUid(data).then((res) => {
				if (res) {
					const {
						uid
					} = res
					commit('SET_VUEX_FROM_ID', uid)
				}
			})

		},
		sendMsg({
				state,
				commit,
				dispatch
			},
			data) {
			data = {
				...data,
				...{
					from_id: state.vuex_from_id,
					to_id: state.vuex_to_id,
					to_name: state.vuex_to_name
				}
			}
			var jsonString = JSON.stringify(data);
			console.log(jsonString, 'zyx');
			state.vuex_ws.send(jsonString)
			// // 发消息
			// $apiFun.sendMsg(data).then((res) => {
			// 	if (res) {
			// 		let chat_record = state.vuex_chat_record
			// 		chat_record.push(res)
			// 		commit('SET_VUEX_CHAT_RECORD', chat_record)
			// 	}
			// })
		},
		//获取设备唯一标识
		async getUniqueCode({
			state,
			commit,
			dispatch
		}) {
			// 改成同步
			let fp = await FingerprintJS.load()
			let result = await fp.get()
			const visitorId = result.visitorId;
			return visitorId
		},
	},
	modules: {},
	// 配置插件
	plugins: [
		// 默认储存在localstorage
		createPersistedState({
			// 本地储存名
			key: 'client_vuex_key_',
			// 指定模块
			// paths: ['chat', 'user', ]
		})
	],
})